import { ArrowRightIcon, Box, Link, Typography } from "nikkei-ui";

import styles from "./Navigation.module.css";

export type NavigationItemState = {
  destinationAfterPaymentsUpdate: string;
};

interface NavigationItemProps {
  label: string;
  href?: string;
  to?: string;
  variant?: "body1" | "subtitle1";
  testId?: string;
  noBorder?: boolean;
  newTab?: boolean;
  state?: NavigationItemState;
  linkSpace?: boolean;
}

export const NavigationItem = ({
  label,
  href,
  to,
  variant = "body1",
  testId,
  noBorder,
  newTab,
  state,
  linkSpace = false,
}: NavigationItemProps): JSX.Element => {
  return (
    <Link
      href={href}
      to={to}
      className={linkSpace ? styles.navItemLink : styles.navItemLinkNoSpace}
      data-testid={testId}
      newTab={newTab}
      state={state}
    >
      <Box
        className={noBorder ? "nid-body-nav-item--noborder" : styles.navItem}
      >
        <Typography as="span" variant={variant} color="link">
          {label}
        </Typography>
        <Box className="nid-nav-icon">
          <ArrowRightIcon />
        </Box>
      </Box>
    </Link>
  );
};

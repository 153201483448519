import type React from "react";

import { Box, ButtonBase as Button } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { path } from "@/routes";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { ContentSkeletonLoader } from "nid-common";
import { useSecurityMfaDisableFeature } from "./hooks/useSecurityMfaDisableFeature";

export const SecurityMfaDisableFeature: React.FC = () => {
  const { t } = useTranslation();
  const { handleDisableMfa, isDisabling, loading } =
    useSecurityMfaDisableFeature();

  if (loading) {
    return <ContentSkeletonLoader />;
  }

  return (
    <Box className="nid-section-inner">
      <Button
        size="full"
        data-testid="button-disable"
        onClick={handleDisableMfa}
        disabled={isDisabling}
      >
        {t("security.mfa.disable.button.disable")}
      </Button>

      <BackButton
        to={path.security.mfa.root}
        buttonText={t("security.mfa.disable.button.back")}
        testId="button-back"
      />
    </Box>
  );
};

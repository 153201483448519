import type React from "react";

import { Box, FormControl } from "nikkei-ui";
import Button from "nikkei-ui/components/Buttons/Button";

import { useTranslation } from "react-i18next";

import { InterestField } from "@/components/ui/Form/InterestField/InterestField";
import { NewsSubscriptionField } from "@/components/ui/Form/NewsSubscriptionField/NewsSubscriptionField";
import { NikkeiResearchMonitorField } from "@/components/ui/Form/NikkeiResearchMonitorField/NikkeiResearchMonitorField";
import { NotificationMailField } from "@/components/ui/Form/NotificationMailField/NotificationMailField";
import { usePersonalSurveyInputFeature } from "@/features/Personal/Survey/hooks/usePersonalSurveyInputFeature";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

export const PersonalSurveyInputFeature: React.FC = () => {
  const { t } = useTranslation();
  const usePersonalSurveyInputFeatureReturn = usePersonalSurveyInputFeature();

  if (usePersonalSurveyInputFeatureReturn.status !== "ok") {
    return <ContentSkeletonLoader />;
  }

  const { register, handleSubmit, buttonEnabled, errors } =
    usePersonalSurveyInputFeatureReturn;

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <NotificationMailField {...register("notificationMail")} />
        <NewsSubscriptionField {...register("newsSubscription")} />
        <NikkeiResearchMonitorField {...register("nikkeiResearchMonitor")} />
        <InterestField {...register("interest")} />

        <Box className="my-2">
          <FormControl.Validation
            className="nid-error-field"
            status={errors?.api ? "error" : undefined}
            data-testid="error-api"
          >
            {errors?.api?.message}
          </FormControl.Validation>
        </Box>

        <Button size="full" data-testid="submit" disabled={!buttonEnabled}>
          {t("personal.edit.button.confirm")}
        </Button>
      </form>

      <Box className="nid-item-center">
        <BackButton
          to={path.personal.root}
          buttonText={t("personal.edit.button.cancel")}
        />
      </Box>
    </>
  );
};

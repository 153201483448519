import type { ContractDetail } from "@/hooks/useContractDetail";
import { path } from "@/routes";
import { PageSkeletonLoader } from "nid-common";
import { AlertMessage, Box, Typography2 } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import { CommonNavList, NavList, ServiceHeader } from "./ServiceCommon";
import styles from "./ServiceFeature.module.css";
import { useServiceFeature } from "./hooks/useServiceFeature";

interface RpSpecificLinks {
  clientId: string;
  links: {
    label: string;
    url: string;
  }[];
  contractDetail?: ContractDetail;
}
const ContractNotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box as="section" className="nid-section nid-section-overflow-nopadding">
      <Box className="nid-section-inner p-0">
        <Box className="nid-section-body mx-4">
          <Typography2 as="p" size="14">
            {t("services.service.no_contract_in_use")}
          </Typography2>
        </Box>
      </Box>
    </Box>
  );
};

const RpSpecificNavList = (props: RpSpecificLinks): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box as="section" className="nid-section nid-section-overflow-nopadding">
      <Box className="nid-section-inner p-0">
        <Box className="nid-section-body">
          {props.contractDetail?.terminationInfo && (
            <AlertMessage
              variant="danger"
              className={`${styles.notification} mx-4`}
              testId={"cancel-notification"}
            >
              <Typography2 as="p" size="14" color="red90">
                {t("services.service_contract.cancel_notice", {
                  cancel_year:
                    props.contractDetail.terminationInfo.applyDate.year,
                  cancel_month:
                    props.contractDetail.terminationInfo.applyDate.month,
                  cancel_date:
                    props.contractDetail.terminationInfo.applyDate.date,
                })}
                {props.contractDetail.firstFreeEndDate &&
                  t("services.service_contract.cancel_notice_first_free")}
              </Typography2>
            </AlertMessage>
          )}
          <NavList
            links={props.links.map((item, index) => ({
              label: item.label,
              testId: `link-${props.clientId}-${index}`,
              url: item.url,
              ...(item.url === path.payments.update.service(props.clientId)
                ? {
                    state: {
                      destinationAfterPaymentsUpdate: path.services.service(
                        props.clientId,
                      ),
                    },
                  }
                : {}),
            }))}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const ServiceFeature = (): JSX.Element => {
  const { status, service, contractDetail } = useServiceFeature();

  if (status !== "ok") return <PageSkeletonLoader />;

  return (
    <>
      <Box className={"mb-4"}>
        <ServiceHeader
          name={service.name}
          logo={service.logo}
          description={service.description}
        />
      </Box>
      {service.links.length > 0 ? (
        <RpSpecificNavList
          clientId={service.clientId}
          links={service.links}
          contractDetail={contractDetail}
        />
      ) : (
        <ContractNotFound />
      )}
      <CommonNavList />
    </>
  );
};

export default ServiceFeature;

import { AccountPanel } from "@/components/ui/AccountTop/AccountPanel";
import { AuthDialog } from "@/components/ui/AccountTop/AuthDialog";
import { ContractDetailsCheck } from "@/components/ui/AccountTop/ContractDetailsCheck";
import { NikkeiIdServices } from "@/components/ui/AccountTop/NikkeiIdServices";
import { OtherProcedures } from "@/components/ui/AccountTop/OtherProcedures";
import { Profile } from "@/components/ui/AccountTop/Profile";
import { PasskeyHero } from "@/components/ui/Passkeys/PasskeyHero";
import { PaymentsUnpaidAlertFeature } from "@/components/ui/Payments/PaymentsAlertFeature";
import { path, enableSecurityPasskey } from "@/routes";
import { PageSkeletonLoader, usePasskeys } from "nid-common";
import { Box } from "nikkei-ui";
import styles from "./AccountTopFeature.module.css";
import { useAccountTopFeature } from "./hooks/useAccountTopFeature";

export const AccountTopFeature = (): JSX.Element => {
  const { status, data, isRelyingPartyRegistered, lastPasswordChanged } =
    useAccountTopFeature();
  const { status: passkeyStatus, data: passkeyData } = usePasskeys();

  const passkeyCount =
    enableSecurityPasskey && passkeyStatus === "ok"
      ? passkeyData.passkeys.length
      : undefined;

  if (status !== "ok") {
    return (
      <Box className={styles.nidLoadingContainer}>
        <PageSkeletonLoader />
      </Box>
    );
  }

  return (
    <>
      <Box className={styles.nidAccountContainer}>
        <Box className={styles.mainSection}>
          {data?.status === "ok" ? <PaymentsUnpaidAlertFeature /> : undefined}
          {data?.status === "ok" ? (
            <Profile userName={data.name} loginId={data.login_id} />
          ) : data?.status === "unauthenticated" ? (
            <AuthDialog />
          ) : undefined}

          {passkeyCount === 0 && path.security.passkeys && (
            <PasskeyHero
              link={path.security.passkeys.root}
              testId="security-item-passkey-empty"
              enableCloseButton={true}
            />
          )}
          <AccountPanel
            isAuthenticated={data?.status === "ok" && !!data.login_id}
            isRelyingPartyRegistered={isRelyingPartyRegistered}
            lastPasswordChanged={lastPasswordChanged}
          />
          <ContractDetailsCheck />
          <OtherProcedures />
        </Box>
      </Box>
      <Box as="section" className={styles.servicesContainer}>
        <Box as="section" className={styles.servicesSection}>
          <NikkeiIdServices />
        </Box>
      </Box>
    </>
  );
};

export default AccountTopFeature;

import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";
import { Link, Typography2 } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import { Layout } from "@/components/ui/Layout";
import { ContractInfoProvider } from "@/features/Services/Contract/Cancel/ContractInfoProvider";
import { useServicePage } from "@/pages/Services/Service/hooks/useServicePage";

export const ServiceCancelPageWrapper = (): JSX.Element => {
  const { t } = useTranslation();
  const pageTitle = t("breadcrumbs.services_contract_cancel");
  const { serviceName, clientId: serviceId } = useServicePage();

  const { pathname } = useLocation();
  const completePath = path.services.contract?.cancel.complete(serviceId);
  const isComplete = completePath ? pathname === completePath : false;

  const breadcrumbText = isComplete
    ? "breadcrumbs.services_contract_cancel_complete"
    : "breadcrumbs.services_contract_cancel";

  return (
    <Layout
      title={pageTitle}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.services.root} variant="h6">
            {t("breadcrumbs.services")}
          </Link>
          <Link to={path.services.service(serviceId)} variant="h6">
            {serviceName}
          </Link>
          <Link to={path.services.contract?.root(serviceId)} variant="h6">
            {t("breadcrumbs.services_contract")}
          </Link>
          <Typography2
            as="span"
            size="14"
            lineHeight="150"
            data-testid="breadcrumb-last-item"
          >
            {t(breadcrumbText)}
          </Typography2>
        </>
      }
      description={t("services.description")}
    >
      <LoginWallProvider maxAge={1800}>
        <ContractInfoProvider>
          <Outlet />
        </ContractInfoProvider>
      </LoginWallProvider>
    </Layout>
  );
};

export default ServiceCancelPageWrapper;

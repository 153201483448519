import type React from "react";

import { PinCodeField } from "nid-common";
import { Box, FlashMessage, FormControl, Link, Typography } from "nikkei-ui";
import { Trans, useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";

import { path } from "@/routes";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { useSecurityMfaEmailConfirmFeature } from "./hooks/useSecurityMfaEmailConfirmFeature";

export const SecurityMfaEmailConfirmFeature: React.FC = () => {
  const { t } = useTranslation();
  const {
    email,
    onFill,
    onChange,
    isSubmitting,
    errorId,
    onResend,
    isResending,
    sendEmail,
  } = useSecurityMfaEmailConfirmFeature();

  return (
    <>
      <ArticleTitle
        title={t("security.mfa.email.confirm.title")}
        testId="article-security-mfa-email-confirm"
      >
        <Typography as="p" className="nid-article-description">
          <Trans
            t={t}
            i18nKey="security.mfa.email.confirm.description"
            shouldUnescape={true}
            values={{ email }}
            components={[
              <Typography key="email" as="span" bold data-dd-privacy="mask" />,
            ]}
          />
        </Typography>
      </ArticleTitle>
      <form>
        <Box
          as="section"
          className="nid-section nid-section-without-background"
        >
          <Box className="nid-section-inner">
            <Box className="nid-otp-container">
              <PinCodeField
                className="mb-1"
                valueLength={6}
                isLoading={isSubmitting}
                onFill={onFill}
                onChange={onChange}
              />

              <FormControl.Validation
                className="nid-error-field"
                status={errorId ? "error" : undefined}
                data-testid="error-otp"
              >
                {errorId ? t(errorId) : undefined}
              </FormControl.Validation>

              {!isSubmitting && sendEmail ? (
                <FlashMessage variant={"success"}>
                  <span className="p-3">
                    {t("security.mfa.email.confirm.text.resendSuccess")}
                  </span>
                </FlashMessage>
              ) : (
                <Box className="nid-item-center nid-typography-type--button">
                  <Link
                    onClick={onResend}
                    loading={isResending}
                    data-testid="button-resent"
                    className={
                      isSubmitting
                        ? "nid-typography-block"
                        : "nid-button-text-submit"
                    }
                  >
                    {t("security.mfa.email.confirm.button.resend")}
                  </Link>
                </Box>
              )}
            </Box>
            <Box className="nid-item-center">
              <BackButton
                to={path.security.mfa.email.root}
                buttonText={t("security.mfa.email.confirm.button.back")}
                testId="back"
              />
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

import type React from "react";

import {
  Box,
  ButtonBase as Button,
  Radio,
  TextField,
  Typography,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { path } from "@/routes";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { useSecurityMfaEmailInputFeature } from "./hooks/useSecurityMfaEmailInputFeature";

export const SecurityMfaEmailInputFeature: React.FC = () => {
  const { t } = useTranslation();
  const {
    email,
    registers,
    handleSubmit,
    emailFieldEnabled,
    buttonEnabled,
    errorMessage,
  } = useSecurityMfaEmailInputFeature();

  return (
    <Box className="nid-section-inner">
      <form onSubmit={handleSubmit} noValidate>
        <Box className="nid-column-item-start nid-item-gap16 pb-6">
          <Box>
            <Radio
              value="true"
              label={t("security.mfa.email.index.label.existing_email")}
              data-testid="radio-existingEmail"
              {...registers.emailSameAsLoginId}
            />
            <Box className="pl-8">
              <Typography bold variant="subtitle1">
                {email}
              </Typography>
            </Box>
          </Box>
          <Box className="w-full">
            <Radio
              value="false"
              label={t("security.mfa.email.index.label.other_email")}
              data-testid="radio-differentEmail"
              {...registers.emailSameAsLoginId}
            />
            <Box className="pl-8">
              <TextField
                labelVariant="head"
                variant="email"
                testId="input-email"
                disabled={emailFieldEnabled}
                status={errorMessage ? "error" : undefined}
                validationMessage={errorMessage}
                required={!emailFieldEnabled}
                placeholder={t("security.mfa.email.index.placeholder.input")}
                {...registers.email}
              />
            </Box>
          </Box>
        </Box>
        <Box className="nid-item-center nid-item-gap16 my-4">
          <Button size="full" data-testid="submit" disabled={buttonEnabled}>
            {t("security.edit.button.submit")}
          </Button>

          <BackButton
            to={path.security.mfa.root}
            buttonText={t("security.edit.button.back")}
          />
        </Box>
      </form>
    </Box>
  );
};

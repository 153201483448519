import { Box, ButtonBase as Button, FormControl } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import BirthField from "@/components/ui/Form/BirthField/BirthField";
import CharacterError from "@/components/ui/Form/CharacterError";
import { NameField } from "@/components/ui/Form/NameField";
import SexField from "@/components/ui/Form/SexField/SexField";
import { usePersonalInfoInputFeature } from "@/features/Personal/Info/hooks/usePersonalInfoInputFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

export const PersonalInfoInputFeature = (): JSX.Element => {
  const { t } = useTranslation();
  const { required, errors, handleSubmit, registers, status, buttonEnabled } =
    usePersonalInfoInputFeature();

  const nameCharacterError =
    errors?.name?.first?.type || errors?.name?.last?.type;
  const nameKanaCharacterError =
    errors?.nameKana?.first?.type || errors?.nameKana?.last?.type;

  if (status !== "ok") return <ContentSkeletonLoader />;

  return (
    <Box as="section" className="nid-section nid-section-without-background">
      <Box className="nid-section-inner">
        <form onSubmit={handleSubmit} noValidate>
          <NameField
            label={t("attributes.name")}
            helperText={t("personal.edit.info.text.name_helper")}
            placeholder={{
              last: t("personal.edit.info.placeholder.last_name"),
              first: t("personal.edit.info.placeholder.first_name"),
            }}
            testId="name"
            errors={errors.name}
            errorDetails={nameCharacterError ? <CharacterError /> : undefined}
            registers={registers.name}
          />
          <NameField
            label={t("attributes.nameKana")}
            placeholder={{
              last: t("personal.edit.info.placeholder.last_name_kana"),
              first: t("personal.edit.info.placeholder.first_name_kana"),
            }}
            testId="name-kana"
            errors={errors.nameKana}
            errorDetails={
              nameKanaCharacterError ? <CharacterError /> : undefined
            }
            registers={registers.nameKana}
          />

          <fieldset className="nid-account-edit-fieldset">
            <BirthField
              required={required.birth}
              errors={errors.birth}
              registers={registers.birth}
            />
          </fieldset>

          <fieldset>
            <SexField {...registers.sex} />
          </fieldset>

          <Box className="mb-2">
            <FormControl.Validation
              className="nid-error-field"
              status={errors.api ? "error" : undefined}
              data-testid="error-api"
            >
              {errors.api?.message}
            </FormControl.Validation>
          </Box>

          <Button size="full" data-testid="submit" disabled={!buttonEnabled}>
            {t("personal.edit.button.confirm")}
          </Button>
        </form>
        <Box className="nid-item-center">
          <BackButton
            to={path.personal.root}
            buttonText={t("personal.edit.button.cancel")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalInfoInputFeature;

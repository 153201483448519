import type React from "react";
import { useRef, useState } from "react";

import {
  Box,
  FormControl,
  type OptionItem,
  Select,
  Typography,
  type UseFormRegisterReturn,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { Row } from "@/components/ui/Row";
import { useMergeRefs } from "@/hooks/useMergeRefs";
import RequiredTag from "../../Tag/RequiredTag";

const DISPLAY_NAME = "Nid.Form.BirthField";

const range = (start: number, end: number) => {
  const values = [];
  for (let i = start; i < end; i++) {
    values.push(i);
  }
  return values;
};

const calcWareki = (year: number) => {
  const date = new Date(year, 0, 1, 0, 0, 0);
  return new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
    era: "short",
  })
    .format(date)
    .split("/")[0];
};

const getMaxDay = (year: number, month: number, today: Date): number => {
  return today.getFullYear() === year && today.getMonth() + 1 === month
    ? today.getDate()
    : new Date(year, month, 0).getDate();
};

const getYearList = () => {
  const { t } = useTranslation();
  const yearList: OptionItem[] = [];
  range(1900, new Date().getFullYear() + 1).map((year) => {
    yearList.push({
      value: year,
      label: t("personal.edit.info.birth.year_with_wareki", {
        year: year,
        wareki: calcWareki(year),
      }),
    });
    if (year === 1979) {
      yearList.push({
        value: 0,
        label: t("personal.edit.info.label.year"),
      });
      return;
    }
  });
  return yearList;
};

const getMonthList = (maxMonth: number) => {
  const { t } = useTranslation();
  return [
    {
      value: 0,
      label: t("personal.edit.info.label.month"),
    },
  ].concat(
    range(1, maxMonth + 1).map((month) => {
      return {
        value: month,
        label: t("personal.edit.info.birth.month", { month }),
      };
    }),
  );
};

const getDateList = (maxDate: number) => {
  const { t } = useTranslation();
  return [
    {
      value: 0,
      label: t("personal.edit.info.label.date"),
    },
  ].concat(
    range(1, maxDate + 1).map((date) => {
      return {
        value: date,
        label: t("personal.edit.info.birth.date", { date }),
      };
    }),
  );
};

const today = new Date();

type BirthFieldProps = {
  required: boolean;
  errors?: {
    year?: { message?: string };
    month?: { message?: string };
    date?: { message?: string };
  };
  registers?: {
    year?: UseFormRegisterReturn<string>;
    month?: UseFormRegisterReturn<string>;
    date?: UseFormRegisterReturn<string>;
  };
  helperText?: React.ReactNode;
  ignore1Password?: boolean;
};

const BirthField: React.FC<BirthFieldProps> = ({
  required,
  registers,
  errors,
  helperText,
  ignore1Password = false,
}) => {
  const { t } = useTranslation();
  const [maxMonth, setMaxMonth] = useState<number>(12);
  const [maxDate, setMaxDate] = useState<number>(31);

  const yearRef = useRef<HTMLSelectElement>(null);
  const monthRef = useRef<HTMLSelectElement>(null);
  const dateRef = useRef<HTMLSelectElement>(null);

  const yearRefs = useMergeRefs(yearRef, registers?.year?.ref);
  const monthRefs = useMergeRefs(monthRef, registers?.month?.ref);
  const dateRefs = useMergeRefs(dateRef, registers?.date?.ref);

  const errorMessage =
    errors?.year?.message || errors?.month?.message || errors?.date?.message;

  const date = (maxMonth: number, maxDate: number) => {
    const month = monthRef?.current?.value ?? "0";
    const date = dateRef.current?.value ?? "0";

    const nextMonth = Number(month) <= maxMonth ? month : "0";
    return {
      year: yearRef.current?.value ?? "0",
      month: nextMonth,
      date:
        Number(date) <= maxDate &&
        (nextMonth !== "0" || monthRef.current?.value === "0")
          ? date
          : "0",
    };
  };

  const onChangeElement = () => {
    if (yearRef.current && monthRef.current && dateRef.current) {
      const nextMaxMonth =
        Number(yearRef.current.value) === today.getFullYear()
          ? today.getMonth() + 1
          : 12;
      setMaxMonth(nextMaxMonth);

      const nextMaxDate = getMaxDay(
        Number(yearRef.current.value),
        Number(monthRef.current.value),
        today,
      );
      setMaxDate(nextMaxDate);

      const nextDate = date(nextMaxMonth, nextMaxDate);
      yearRef.current.value = nextDate.year;
      monthRef.current.value = nextDate.month;
      dateRef.current.value = nextDate.date;
      registers?.year?.onChange({
        target: { name: registers.year.name, value: nextDate.year },
      });
      registers?.month?.onChange({
        target: { name: registers.month.name, value: nextDate.month },
      });
      registers?.date?.onChange({
        target: { name: registers.date.name, value: nextDate.date },
      });
    }
  };

  return (
    <>
      <Typography
        as="legend"
        variant="body"
        bold
        className="mb-2"
        color={errorMessage ? "error" : undefined}
      >
        <label htmlFor="select-birth-year">{t("attributes.birth")}</label>
        <RequiredTag on={required} />
      </Typography>
      <Row className="nid-space-start mb-1">
        <Select
          name="year"
          options={getYearList()}
          id="select-birth-year"
          data-testid="select-birth-year"
          containerclassname="nid-select-year"
          status={errors?.year?.message ? "error" : undefined}
          autoComplete={"bday-year"}
          {...registers?.year}
          ref={yearRefs}
          onChange={onChangeElement}
          data-1p-ignore={ignore1Password}
        />
        <Select
          name="month"
          options={getMonthList(maxMonth)}
          id="select-birth-month"
          data-testid="select-birth-month"
          status={errors?.month?.message ? "error" : undefined}
          autoComplete={"bday-month"}
          {...registers?.month}
          ref={monthRefs}
          onChange={onChangeElement}
          data-1p-ignore={ignore1Password}
        />
        <Select
          name="date"
          options={getDateList(maxDate)}
          id="select-birth-date"
          data-testid="select-birth-date"
          status={errors?.date?.message ? "error" : undefined}
          autoComplete={"bday-day"}
          {...registers?.date}
          ref={dateRefs}
          onChange={onChangeElement}
          data-1p-ignore={ignore1Password}
        />
      </Row>
      {helperText && <Box className={"mb-2"}>{helperText}</Box>}
      <FormControl.Validation
        className="nid-error-field"
        status={errorMessage ? "error" : undefined}
        data-testid="error-birth"
      >
        {errorMessage ? errorMessage : undefined}
      </FormControl.Validation>
    </>
  );
};

BirthField.displayName = DISPLAY_NAME;
const Root = BirthField;
export { Root, BirthField };
export type { BirthFieldProps };
export default BirthField;

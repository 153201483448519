import { Button, Link, Modal, QuestionCircleIcon, Typography } from "nikkei-ui";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ServiceUnavailableHoursModal.module.css";

export const ServiceUnavailableHoursModal: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <>
      <Link className={`${styles.iconLink} w-fit-content`}>
        <QuestionCircleIcon className={`${styles.icon} mr-1`} />
        <Typography
          as="span"
          variant="caption"
          color={"link"}
          onClick={openModal}
        >
          {t("payments.update.modal.service_unavailable_hours.title")}
        </Typography>
      </Link>
      <Modal
        title={t("payments.update.modal.service_unavailable_hours.title")}
        isOpen={isModalOpen}
        onClose={closeModal}
        testId="service-unavailable-hours-modal"
      >
        <Typography as="span">
          {t("payments.update.modal.service_unavailable_hours.text")}
        </Typography>

        <Button
          size={"full"}
          onClick={closeModal}
          data-testid={"button-close-service-unavailable-hours-modal"}
          type="button"
          className={"mt-6"}
        >
          {t("payments.update.button.close")}
        </Button>
      </Modal>
    </>
  );
};

import type * as React from "react";

import { Box, Link, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PaymentsFeature } from "@/features/Payments/PaymentsFeature";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";

import { ServiceUnavailableHoursModal } from "@/components/ui/Modal/ServiceUnavailableHoursModal";
import { PaymentsAlertFeature } from "@/components/ui/Payments/PaymentsAlertFeature";
import { Layout } from "../../components/ui/Layout";
import styles from "./index.module.css";

const PaymentsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.payments")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Typography as="span" variant="h6">
            {t("breadcrumbs.payments")}
          </Typography>
        </>
      }
      description={t("payments.top.description")}
    >
      <LoginWallProvider>
        <PaymentsAlertFeature />
        <ArticleTitle
          title={t("payments.top.title.main")}
          testId="article-payments"
        >
          <Box className={styles.description}>
            <Typography as="p" className="nid-article-description">
              {t("payments.top.description")}
            </Typography>
            <ServiceUnavailableHoursModal />
          </Box>
        </ArticleTitle>
        <PaymentsFeature />
      </LoginWallProvider>
    </Layout>
  );
};

export default PaymentsPage;

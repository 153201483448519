import type React from "react";

import { Box, ExternalLinkIcon, Link, LinkButton, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import styles from "./NewsletterCard.module.css";

interface Props {
  serviceName: string;
  contents: {
    title: string;
    target?: string;
    description: string;
    time?: string;
    link: string;
    linkType: "configuration" | "service_top";
  }[];
  testId: string;
}

export const NewsletterCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Box as="section" className="nid-section nid-section-overflow mb-6">
      <Box className="nid-section-inner nid-section-inner-gap24 mt-2">
        <Typography as="h2" variant="h3" bold data-testid={props.testId}>
          {props.serviceName}
        </Typography>
        {props.contents.map((content, index) => {
          return (
            <Box key={content.title}>
              <hr className="nid-separator" />
              <Box className={styles.header}>
                <Box>
                  <Typography
                    variant="h3"
                    color="accent"
                    bold
                    className="nid-section-title mb-1"
                    data-testid={`${props.testId}-item-${index}`}
                  >
                    {content.title}
                  </Typography>
                  <Typography
                    className="nid-typography-support-text"
                    color="disabled"
                  >
                    {content.target}
                  </Typography>
                </Box>
                {content.linkType === "configuration" && (
                  <LinkButton
                    href={content.link}
                    variant="border"
                    className={styles.changeButton}
                    newTab
                    data-testid={`${props.testId}-item-${index}-button`}
                  >
                    {t("newsletters.index.button.configure")}
                  </LinkButton>
                )}
              </Box>
              <Typography variant="body1">{content.description}</Typography>
              {content.time && (
                <Typography variant="body1">{content.time}</Typography>
              )}
              {content.linkType === "service_top" && (
                <Box className="mt-4">
                  <Link
                    href={content.link}
                    className={`${styles.textLink} w-fit-content`}
                    newTab
                    data-testid={`${props.testId}-item-${index}-link`}
                  >
                    {t("newsletters.index.button.detail")}
                    <ExternalLinkIcon />
                  </Link>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

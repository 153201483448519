import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { Layout } from "@/components/ui/Layout";
import { PaymentsUnpaidAlertFeature } from "@/components/ui/Payments/PaymentsAlertFeature";
import type { CardUpdateAppliedService } from "@/features/Payments/Update/usePaymentsUpdateNavigate";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";
import { Box, Link, LinkButton, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styles from "./complete.module.css";

const PaymentsUpdateCompletePage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const services = location.state.services as CardUpdateAppliedService[];
  const destinationAfterPaymentsUpdate = location.state
    ?.destinationAfterPaymentsUpdate as string | undefined;

  return (
    <Layout
      title={t("breadcrumbs.payments_card_update_complete")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.payments.root} variant="h6">
            {t("breadcrumbs.payments")}
          </Link>
          <Typography as="span" variant="h6">
            {t("breadcrumbs.payments_card_update_complete")}
          </Typography>
        </>
      }
      description={t("payments.top.description")}
    >
      <LoginWallProvider>
        <PaymentsUnpaidAlertFeature />
        <ArticleTitle
          testId="article-payments-update-complete"
          title={t("payments.update.complete.title")}
        >
          <Typography as="p" className="nid-article-description">
            {t("payments.update.complete.description")}
          </Typography>
        </ArticleTitle>
        <Box className="nid-section-inner">
          <Box className="nid-section-body" data-testid="applied-services">
            {services && services?.length > 0 ? (
              <ul>
                {services.map((service) => (
                  <Typography
                    as="li"
                    className={styles.serviceItem}
                    key={service.serviceId}
                  >
                    {service.name}
                    {service.planName && <>（{service.planName}）</>}
                  </Typography>
                ))}
              </ul>
            ) : (
              <></>
            )}
          </Box>

          {destinationAfterPaymentsUpdate ? (
            <LinkButton
              to={destinationAfterPaymentsUpdate}
              data-testid="submit"
              size="full"
              className="mt-10"
            >
              {t("payments.update.complete.button.back_to_service")}
            </LinkButton>
          ) : (
            <LinkButton
              to={path.payments.root}
              data-testid="submit"
              size="full"
              className="mt-10"
            >
              {t("payments.update.complete.button.back")}
            </LinkButton>
          )}
        </Box>
      </LoginWallProvider>
    </Layout>
  );
};

export default PaymentsUpdateCompletePage;

import { Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { SecurityPasswordChangeFormProvider } from "@/features/Security/Password/Change/SecurityPasswordChangeFormProvider";
import { path, enableSecurityPasskey } from "@/routes";

import { Layout } from "../../../../components/ui/Layout";

export const SecurityPasswordChangePageWrapper = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("breadcrumbs.security_password_change")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.security.root} variant="h6">
            {t("breadcrumbs.security")}
          </Link>
          <Typography as="span" variant="h6">
            {t("breadcrumbs.security_password_change")}
          </Typography>
        </>
      }
      description={
        enableSecurityPasskey
          ? t("security.description")
          : t("security.old_description")
      }
    >
      <SecurityPasswordChangeFormProvider>
        <Outlet />
      </SecurityPasswordChangeFormProvider>
    </Layout>
  );
};

export default SecurityPasswordChangePageWrapper;

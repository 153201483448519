import type React from "react";

import { Box } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet, ScrollRestoration } from "react-router-dom";

import { Sidebar } from "@/components/ui/Sidebar";
import { path } from "@/routes";

import { useLeaveApp } from "nid-common";
import { OIDC_API_LOGOUT } from "nid-common/api/connect";
import { Header } from "../Header/Header";

export const AppLayout: React.FC = () => {
  const { t } = useTranslation();
  const { redirect } = useLeaveApp();
  const sideNavList = [
    {
      title: t("layout.navigation.title.top"),
      to: path.root,
      testId: "sidebar-navigation-top",
    },
    {
      title: t("layout.navigation.title.personal"),
      to: path.personal.root,
      supportText: t("layout.navigation.support_text.personal"),
      testId: "sidebar-navigation-personal",
    },
    {
      title: t("layout.navigation.title.payments"),
      to: path.payments.root,
      supportText: t("layout.navigation.support_text.payments"),
      testId: "sidebar-navigation-payments",
    },
    {
      title: t("layout.navigation.title.security"),
      to: path.security.root,
      supportText: t("layout.navigation.support_text.security"),
      testId: "sidebar-navigation-security",
    },
    {
      title: t("layout.navigation.title.services"),
      to: path.services.root,
      supportText: t("layout.navigation.support_text.services"),
      testId: "sidebar-navigation-services",
    },
    {
      title: t("layout.navigation.title.newsletters"),
      to: path.newsletters.root,
      supportText: t("layout.navigation.support_text.newsletters"),
      testId: "sidebar-navigation-newsletters",
    },
  ];

  const logoutUrl = `${import.meta.env.VITE_ORIGIN}${OIDC_API_LOGOUT}?client_id=NID&post_logout_redirect_uri=${import.meta.env.VITE_ORIGIN}${import.meta.env.BASE_URL}`;

  const logout = () => {
    redirect(logoutUrl);
  };

  return (
    <>
      <ScrollRestoration />
      <Box className="nid-page">
        <Header handleLogout={logout} />
        <main className="nid-split-layout">
          <Box className="nid-split-layout--left">
            <aside className="nid-nav">
              <Sidebar navList={sideNavList} />
            </aside>
          </Box>
          <Box className="nid-split-layout--right">
            <Outlet />
          </Box>
        </main>
      </Box>
    </>
  );
};

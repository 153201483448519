import { BackButton } from "@/components/ui/BackButton/BackButton";
import { CardPaymentHistories } from "@/components/ui/Payments/Histories/CardPaymentHistories";
import { usePaymentHistoriesFeature } from "@/features/Payments/Histories/usePaymentHistoriesFeature";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";
import { Box, Select, Typography } from "nikkei-ui";
import type React from "react";
import { useTranslation } from "react-i18next";
import styles from "./PaymentHistoriesFeature.module.css";

export const PaymentHistoriesFeature: React.FC = () => {
  const { t } = useTranslation();
  const r = usePaymentHistoriesFeature();

  if (r.status === "loading") {
    return <ContentSkeletonLoader />;
  }

  if (r.status === "empty") {
    return (
      <>
        <Box as="section" className="nid-section nid-section-overflow">
          <Box className="nid-section-inner">
            {t("payments.histories.empty")}
          </Box>
        </Box>
        <Cautions />
      </>
    );
  }

  const { selects, onChangeSelect, selectedValue, displayHistories } = r;

  return (
    <>
      <Box className="nid-section-without-background">
        <Box className="nid-section-inner">
          <Select
            options={selects}
            name={"payment-date"}
            value={selectedValue}
            containerclassname={"w-select-small mb-6"}
            onChange={onChangeSelect}
            data-testid={"select-payment-date"}
          />
        </Box>
      </Box>

      {displayHistories.map(([card, histories]) => {
        return (
          <Box
            as="section"
            className="nid-section nid-section-overflow"
            key={card.id}
          >
            <Box className="nid-section-inner">
              <CardPaymentHistories card={card} histories={histories} />
            </Box>
          </Box>
        );
      })}

      <Cautions />
    </>
  );
};

const Cautions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner">
          <ul>
            <li className={styles.list}>
              <Typography as="span" variant={"h5"}>
                {t("payments.histories.cautions.other_histories")}
              </Typography>
            </li>
            <li className={styles.list}>
              <Typography as="span" variant={"h5"}>
                {t("payments.histories.cautions.credit_card_receipt")}
              </Typography>
            </li>
            <li className={styles.list}>
              <Typography as="span" variant={"h5"}>
                {t("payments.histories.cautions.used_cards")}
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
      <Box className="nid-item-center mt-6">
        <BackButton
          to={path.payments.root}
          buttonText={t("payments.histories.back")}
        />
      </Box>
    </>
  );
};

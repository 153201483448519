import type React from "react";
import { createContext, useContext, useState } from "react";

type ContractInfo = {
  clientId: string;
  serviceName: string;
  planName: string;
  isPaidPlan: boolean;
};

type ContractInfoContextType = {
  contractInfo: ContractInfo | undefined;
  setContractInfo: (data: ContractInfo) => void;
};

export const ContractInfoContext = createContext<
  ContractInfoContextType | undefined
>(undefined);

/**
 * useContractInfoProvider
 * @returns Context
 */
export const useContractInfoProvider = () => {
  const context = useContext(ContractInfoContext);
  if (context === undefined) {
    throw new Error(
      "useContractInfoProvider must be used within a ContractInfoProvider",
    );
  }
  return context;
};

type ContractInfoProviderProps = {
  children: React.ReactNode;
};

/**
 * ContractInfoProvider
 * @param ContractInfoProvider
 * @returns
 */
export const ContractInfoProvider: React.FC<ContractInfoProviderProps> = ({
  children,
}) => {
  const [contractInfo, setContractInfo] =
    useState<ContractInfoContextType["contractInfo"]>(undefined);

  return (
    <ContractInfoContext.Provider value={{ contractInfo, setContractInfo }}>
      {children}
    </ContractInfoContext.Provider>
  );
};

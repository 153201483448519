import type React from "react";

import {
  Box,
  ButtonBase as Button,
  FlashMessage,
  LoadingButton,
  Typography,
} from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { BackupCodeCard } from "@/components/ui/BackupCodeCard";

import { useFileOperations } from "@/hooks/useFileOperations";
import { path } from "@/routes";
import { ContentSkeletonLoader } from "nid-common";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { useSecurityMfaBackupCodeFeature } from "./hooks/useSecurityMfaBackupCodeFeature";

export const SecurityMfaBackupCodeFeature: React.FC = () => {
  const { t } = useTranslation();
  const {
    status,
    isLoading,
    backupCode,
    generatedBackupcode,
    handleClickGenerateNewBackupCode,
  } = useSecurityMfaBackupCodeFeature();
  const { handleClickDownloadTextFile, handleClickPrintPage } =
    useFileOperations();

  if (status !== "ok") return <ContentSkeletonLoader />;

  return (
    <>
      <Box
        as="section"
        className="nid-column-item-start nid-item-gap8 print-section"
      >
        <BackupCodeCard backupCode={backupCode} />
        <Typography as="p" variant="caption" color="disabled">
          {t("security.mfa.backup_code.code_helper_text")}
        </Typography>
      </Box>
      <Box className="nid-column-item-start nid-item-gap16 my-4 print-hidden">
        <Button
          size="full"
          data-testid="button-download"
          onClick={() =>
            handleClickDownloadTextFile(
              backupCode,
              "backup-code.txt",
              "text/plain",
            )
          }
        >
          {t("security.mfa.backup_code.button.download")}
        </Button>
        <Button
          size="full"
          className="nid-hidden-mobile"
          data-testid="button-print"
          onClick={handleClickPrintPage}
        >
          {t("security.mfa.backup_code.button.print")}
        </Button>
      </Box>

      <Box className="nid-column-item-start nid-item-gap16 my-4 print-hidden">
        <Box className="nid-button-full nid-column-item-start nid-item-gap8">
          {generatedBackupcode ? (
            <Box className="nid-button-full nid-column-item-center">
              <FlashMessage
                variant={generatedBackupcode === "error" ? "danger" : "success"}
              >
                <span className="p-3">
                  {generatedBackupcode === "success"
                    ? t("security.mfa.backup_code.new_code.success")
                    : t("security.mfa.backup_code.new_code.error")}
                </span>
              </FlashMessage>
            </Box>
          ) : (
            <Box className="nid-item-center nid-typography-type--button">
              <LoadingButton
                size="full"
                variant="border"
                onClick={handleClickGenerateNewBackupCode}
                loading={isLoading}
                data-testid="button-new-code"
              >
                {t("security.mfa.backup_code.button.new_code")}
              </LoadingButton>
            </Box>
          )}
          <Typography as="p" variant="caption" color="disabled">
            {t("security.mfa.backup_code.button.new_code_helper_text")}
          </Typography>
        </Box>

        <Box className="nid-item-center nid-button-full">
          <BackButton
            to={path.security.mfa.root}
            buttonText={t("security.mfa.backup_code.button.back")}
            testId="button-back"
          />
        </Box>
      </Box>
    </>
  );
};

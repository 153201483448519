import type React from "react";

import { Footer, FooterNavigation } from "@/components/ui/Footer/Footer";
import { AccountTopFeature } from "@/features/AccountTop/AccountTopFeature";
import { Box } from "nikkei-ui";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

export const AccountTopPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("site_name")}</title>
        <meta name="description" content={t("description")} />
      </Helmet>
      <Box className={`${styles.accountTopStyle} account-top`}>
        <AccountTopFeature />
        <Footer>
          <FooterNavigation />
        </Footer>
      </Box>
    </>
  );
};

export default AccountTopPage;

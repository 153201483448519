import type React from "react";

import { useTranslation } from "react-i18next";

import { Box, Button } from "nikkei-ui";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./PaymentsDisplaySwitch.module.css";

const URL_HASH = ["#card", "#service"];

export const PaymentsDisplaySwitch: React.FC<{
  showTab: boolean;
  children: [React.ReactNode, React.ReactNode];
}> = ({ showTab, children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState(location.hash === URL_HASH[1] ? 1 : 0);
  const { t } = useTranslation();
  const handleSwitch = (state: number) => {
    setState(state);
    navigate(URL_HASH[state], { replace: true });
  };

  return (
    <>
      {showTab && (
        <Box
          className={`nid-section nid-section-overflow nid-background-transparent my-0 pt-0 ${styles.switchTabs}`}
        >
          <Box className="nid-section-inner pl-0 pr-0">
            <Box className={styles.paymentsDisplaySwitchButtons}>
              <Button
                type="button"
                className={state === 0 ? styles.active : styles.disabled}
                onClick={() => handleSwitch(0)}
                data-testid="payments-display-switch-card"
              >
                {t("payments.top.displaySwitch.card")}
              </Button>
              <Button
                type="button"
                className={state === 1 ? styles.active : styles.disabled}
                onClick={() => handleSwitch(1)}
                data-testid="payments-display-switch-service"
              >
                {t("payments.top.displaySwitch.service")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {children[state]}
    </>
  );
};

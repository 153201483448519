import { Link, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { PersonalAddressFormProvider } from "@/features/Personal/Address/PersonalAddressFormProvider";
import { path } from "@/routes";
import { LoginWallProvider } from "nid-common";

import { Layout } from "../../../components/ui/Layout";

export const PersonalAddressPageWrapper = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Layout
      title={t("breadcrumbs.personal_address")}
      breadcrumbs={
        <>
          <Link to={path.root} variant="h6">
            {t("breadcrumbs.top")}
          </Link>
          <Link to={path.personal.root} variant="h6">
            {t("breadcrumbs.personal")}
          </Link>
          <Typography as="span" variant="h6">
            {t("breadcrumbs.personal_address")}
          </Typography>
        </>
      }
      description={t("personal.description")}
    >
      <LoginWallProvider>
        <PersonalAddressFormProvider>
          <Outlet />
        </PersonalAddressFormProvider>
      </LoginWallProvider>
    </Layout>
  );
};

export default PersonalAddressPageWrapper;

import { getContractDetail } from "nid-common/api/account/getContractDetail";
import useSWR from "swr";

export type ContractDetail = {
  serviceName: string;
  planId: string;
  planName: string;
  isPaidPlan: boolean;
  isFreeTrial: boolean;
  planPriceInfo?: {
    price: number;
    setDiscountedPrice?: number;
    setDiscountFlag: "no_set_discount" | "not_applied" | "applied";
  };
  firstFreeEndDate?: {
    year: number;
    month: number;
    date: number;
  };
  afterFreePayment?: {
    price: number;
    setDiscountedPrice?: number;
    setDiscountFlag: "no_set_discount" | "applied" | "not_applied";
    paymentDate: {
      year: number;
      month: number;
      date: number;
    };
    days: number;
  };
  appliedCouponInfo?: {
    name: string;
    discountPrice: number;
    discountEndDate: {
      year: number;
      month: number;
    };
  };
  terminationInfo?: {
    applyDate: {
      year: number;
      month: number;
      date: number;
    };
  };
  isTrial: boolean;
};

export const useContractDetail = (clientId: string) => {
  const r = useSWR(`/account/service/${clientId}/contract`, () =>
    getContractDetail(clientId),
  );

  if (r.isLoading) {
    return { status: "loading" } as const;
  }

  if (
    r.error &&
    ((r.error.status === 404 && r.error.response.data.error === "not_found") ||
      (r.error.status === 400 &&
        r.error.response.data.error === "invalid_request"))
  ) {
    return { status: "contract_not_found" } as const;
  }

  if (
    r.error &&
    r.error.status === 503 &&
    r.error.response.data.error === "nkdk_inactive"
  ) {
    return { status: "nkdk_inactive" } as const;
  }

  if (r.error || !r.data) {
    return { status: "error" } as const;
  }

  const contract = r.data.data.contract_info_group[0];

  if (!contract.plan_id || !contract.plan_name) {
    return { status: "contract_not_found" } as const;
  }

  const contractDetail: ContractDetail = {
    serviceName: contract.service_name,
    planId: contract.plan_id,
    planName: contract.plan_name,
    isPaidPlan: contract.plan_charge_type === "2",
    isFreeTrial: isFreeTrial(
      new Date(r.data.headers.date),
      contract.first_free_info?.first_free_end_date,
    ),
    planPriceInfo: contract.plan_price_info
      ? {
          price: contract.plan_price_info.price,
          setDiscountedPrice: contract.plan_price_info.set_discounted_price,
          setDiscountFlag:
            contract.plan_price_info.set_discount_flg === "0"
              ? "not_applied"
              : contract.plan_price_info.set_discount_flg === "1"
                ? "applied"
                : "no_set_discount",
        }
      : undefined,
    firstFreeEndDate: contract.first_free_info
      ? parseNkdkDate(contract.first_free_info.first_free_end_date)
      : undefined,
    afterFreePayment: contract.after_free_payment
      ? {
          price: contract.after_free_payment.price,
          setDiscountedPrice: contract.after_free_payment.set_discounted_price,
          setDiscountFlag:
            contract.after_free_payment.set_discount_flg === "0"
              ? "no_set_discount"
              : contract.after_free_payment.set_discount_flg === "1"
                ? "applied"
                : "not_applied",
          paymentDate: parseNkdkDate(contract.after_free_payment.payment_date),
          days: contract.after_free_payment.days || 0,
        }
      : undefined,
    appliedCouponInfo: contract.applied_coupon_info
      ? {
          name: contract.applied_coupon_info.coupon_name,
          discountPrice: contract.applied_coupon_info.discount_price,
          discountEndDate: parseNkdkYearMonth(
            contract.applied_coupon_info.discount_end_date,
          ),
        }
      : undefined,
    terminationInfo: contract.reservation_info?.termination_info
      ? {
          applyDate: parseNkdkDate(
            contract.reservation_info.termination_info.apply_date,
          ),
        }
      : undefined,
    isTrial: contract.trial_info
      ? isFreeTrial(
          new Date(r.data.headers.date),
          contract.trial_info.trial_end_datetime,
        )
      : false,
  };

  return {
    mutate: r.mutate,
    status: "ok",
    contractDetail,
  } as const;
};

const parseNkdkDate = (date: string) => {
  return {
    year: Number(date.slice(0, 4)),
    month: Number(date.slice(4, 6)),
    date: Number(date.slice(6, 8)),
  };
};

const parseNkdkYearMonth = (date: string) => {
  return {
    year: Number(date.slice(0, 4)),
    month: Number(date.slice(4, 6)),
  };
};

const isFreeTrial = (now: Date, date: string | undefined) => {
  if (!date) return false;
  const yymmdd = {
    year: Number(date.slice(0, 4)),
    month: Number(date.slice(4, 6)),
    date: Number(date.slice(6, 8)),
  };
  const exp = new Date(yymmdd.year, yymmdd.month - 1, yymmdd.date + 1);
  return now < exp;
};

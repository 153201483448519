import type React from "react";

import { Box, ButtonBase as Button, FormControl } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { PersonalConfirmItem } from "@/components/ui/PersonalConfirmItem/PersonalConfirmItem";
import { usePersonalSurveyConfirmFeature } from "@/features/Personal/Survey/hooks/usePersonalSurveyConfirmFeature";
import { path } from "@/routes";

export const PersonalSurveyConfirmFeature: React.FC = () => {
  const { displayValues, handleSubmit, errors, buttonEnabled, inputDone } =
    usePersonalSurveyConfirmFeature();
  const { t } = useTranslation();

  if (!inputDone) return <></>;
  return (
    <form onSubmit={handleSubmit}>
      <Box as="section" className="nid-section nid-section-overflow">
        <Box className="nid-section-inner">
          <Box className="nid-section-body">
            <PersonalConfirmItem
              values={[
                {
                  key: t("attributes.notification_mail"),
                  values: displayValues.notificationMail,
                },
                {
                  key: t("attributes.news_subscription"),
                  values: displayValues.newsSubscription,
                },
                {
                  key: t("attributes.nikkei_research_monitor"),
                  values: displayValues.nikkeiResearchMonitor,
                },
                {
                  key: t("attributes.interest"),
                  values: displayValues.interest,
                },
              ]}
            />
          </Box>
        </Box>
      </Box>

      <Box className="my-2">
        <FormControl.Validation
          className="nid-error-field"
          status={errors?.api ? "error" : undefined}
          data-testid="error-api"
        >
          {errors?.api?.message}
        </FormControl.Validation>
      </Box>

      <Box className="nid-confirm-submitarea pt-0">
        <Button size="full" data-testid="submit" disabled={!buttonEnabled}>
          {t("personal.edit.button.submit")}
        </Button>
        <Box className="nid-item-center">
          {errors?.api ? (
            <BackButton
              to={path.personal.root}
              buttonText={t("personal.edit.button.back_to_personal")}
            />
          ) : (
            <BackButton
              to={path.personal.survey.root}
              buttonText={t("personal.edit.button.edit")}
            />
          )}
        </Box>
      </Box>
    </form>
  );
};

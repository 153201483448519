import type React from "react";

import { PinCodeField } from "nid-common";
import { Box, FormControl } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import { path } from "@/routes";

import { BackButton } from "@/components/ui/BackButton/BackButton";
import { useSecurityMfaAuthenticatorConfirmFeature } from "./hooks/useSecurityMfaAuthenticatorConfirmFeature";

export const SecurityMfaAuthenticatorConfirmFeature: React.FC = () => {
  const { t } = useTranslation();
  const { onFill, onChange, isSubmitting, errorId } =
    useSecurityMfaAuthenticatorConfirmFeature();

  return (
    <form>
      <Box as="section" className="nid-section nid-section-without-background">
        <Box className="nid-section-inner">
          <Box className="nid-otp-container">
            <PinCodeField
              className="mb-1"
              valueLength={6}
              isLoading={isSubmitting}
              onFill={onFill}
              onChange={onChange}
            />
            <FormControl.Validation
              className="nid-error-field"
              status={errorId ? "error" : undefined}
              data-testid="error-otp"
            >
              {errorId ? t(errorId) : undefined}
            </FormControl.Validation>
          </Box>
          <Box className="nid-item-center">
            <BackButton
              to={path.security.mfa.authenticator.root}
              testId="button-back"
              buttonText={t("security.mfa.authenticator.confirm.button.back")}
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
};
